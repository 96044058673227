<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Testimonials</div>
                </div>
            </div>
        </div>

        <div
            class="c-testimonials js-testimonials"
            :class="[
                `t-${componentData.settings.theme}`,
                { 'has-no-padding': componentData.settings.remove_padding == true },
            ]"
            :data-component-id="componentId">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-middle">
                    <div class="small-12 medium-6 cell">
                        <div class="c-testimonials__media-wrapper">
                            <div
                                class="c-testimonials__media is-primary"
                                v-html="componentData.image.attachments['large']"></div>
                            <div
                                class="c-testimonials__media is-secondary"
                                v-html="componentData.secondary_image.attachments['large']"></div>
                        </div>
                    </div>

                    <div class="small-12 medium-5 medium-offset-1 large-4 cell">
                        <div class="c-testimonials__content-wrapper">
                            <template v-if="componentData.title">
                                <h2
                                    v-if="componentData.settings.heading_level == 'h2'"
                                    class="c-testimonials__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h2>

                                <h3
                                    v-else-if="componentData.settings.heading_level == 'h3'"
                                    class="c-testimonials__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h3>

                                <div
                                    v-else
                                    class="c-testimonials__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></div>
                            </template>

                            <div class="s-swiper">
                                <div class="c-testimonials__items">
                                    <swiper-container
                                        ref="containerRef"
                                        :a11y-enabled="true"
                                        :auto-height="true"
                                        :modules="[SwiperA11y, SwiperNavigation, SwiperPagination]"
                                        :navigation-enabled="true"
                                        :navigation-next-el="`.js-testimonials[data-component-id=${componentId}] .js-swiper-button-next`"
                                        :navigation-prev-el="`.js-testimonials[data-component-id=${componentId}] .js-swiper-button-prev`"
                                        :pagination-enabled="true"
                                        :pagination-clickable="true"
                                        :pagination-el="`.js-testimonials[data-component-id=${componentId}] .js-swiper-pagination`"
                                        :slides-per-view="1"
                                        :space-between="40">
                                        <template v-if="componentData.content_source == 'post_type'">
                                            <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
                                                <div class="c-testimonials__item">
                                                    <div
                                                        class="c-testimonials__item-content s-wp-cms wow animate__animated animate__fadeIn"
                                                        v-html="testimonial.acf.content"></div>

                                                    <div
                                                        class="c-testimonials__item-author wow animate__animated animate__fadeIn"
                                                        v-html="testimonial.title.rendered"></div>

                                                    <NuxtLink
                                                        v-if="testimonial.acf.link"
                                                        :to="testimonial.acf.link.url"
                                                        :target="testimonial.acf.link.target"
                                                        class="c-testimonials__item-link wow animate__animated animate__fadeIn">
                                                        <span v-html="testimonial.acf.link.title"></span>
                                                    </NuxtLink>
                                                </div>
                                            </swiper-slide>
                                        </template>

                                        <template v-else-if="componentData.content_source == 'custom'">
                                            <swiper-slide
                                                v-for="(testimonial, index) in componentData.custom_testimonials"
                                                :key="index">
                                                <div class="c-testimonials__item">
                                                    <div
                                                        class="c-testimonials__item-content s-wp-cms wow animate__animated animate__fadeIn"
                                                        v-html="testimonial.testimonial"></div>

                                                    <div
                                                        v-if="testimonial.author"
                                                        class="c-testimonials__item-author wow animate__animated animate__fadeIn"
                                                        v-html="testimonial.author"></div>

                                                    <NuxtLink
                                                        v-if="testimonial.link"
                                                        :to="testimonial.link.url"
                                                        :target="testimonial.link.target"
                                                        class="c-testimonials__item-link wow animate__animated animate__fadeIn">
                                                        <span v-html="testimonial.link.title"></span>
                                                    </NuxtLink>
                                                </div>
                                            </swiper-slide>
                                        </template>
                                    </swiper-container>
                                </div>

                                <div
                                    v-if="
                                        (componentData.content_source == 'post_type' &&
                                            componentData.testimonials.length > 1) ||
                                        (componentData.content_source == 'custom' &&
                                            componentData.custom_testimonials.length > 1)
                                    "
                                    class="swiper-pagination js-swiper-pagination"></div>

                                <div
                                    v-if="
                                        (componentData.content_source == 'post_type' &&
                                            componentData.testimonials.length > 1) ||
                                        (componentData.content_source == 'custom' &&
                                            componentData.custom_testimonials.length > 1)
                                    "
                                    class="swiper-navigation">
                                    <button
                                        class="swiper-button-prev js-swiper-button-prev"
                                        aria-label="Previous slide"
                                        type="button">
                                        <i class="fa-sharp fa-chevron-left"></i>
                                    </button>

                                    <button
                                        class="swiper-button-next js-swiper-button-next"
                                        aria-label="Next slide"
                                        type="button">
                                        <i class="fa-sharp fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const config = useRuntimeConfig();
    const componentId = useId();
    const containerRef = ref(null);
    const swiper = useSwiper(containerRef);
    const testimonials = ref([]);

    const getTestimonials = (items) => {
        // console.log(items);

        items.forEach(async (item) => {
            // console.log('Item: ', item);

            const { data } = await useFetch(`/wp-json/wp/v2/holgate-testimonial/${item.ID}`, {
                baseURL: config.public.cmsBaseUrl,
                query: {
                    acf_format: "standard",
                },
            });

            // console.log('Testimonial Data: ', data.value);

            testimonials.value.push(data.value);
        });
    };

    // console.log("Data: ", props.componentData);

    if (props.componentData.content_source == "post_type") {
        await getTestimonials(props.componentData.testimonials);

        // console.log("Testimonials: ", testimonials.value);
    }
</script>
